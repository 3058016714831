
import { authSlice, scanSuccess } from '../../slices/authSlice'
import { type RootState, type AppDispatch } from '../../store'

import { type IFields } from '../../../types/userTypes/documentTypes/TScan'

import { uploadFile } from '../documentsActions/upload/uploadFile'

export const scanFields = (formValues: IFields) => async (dispatch: AppDispatch, getState: () => RootState) => {
    let errorRedirect = '/auth/register_scan_emirates'
    let successRedirect = '/auth/write_yourself_fields'
    try {
        dispatch(authSlice.actions.authFetching(true))

        const savedFile = await dispatch(uploadFile(formValues))

        if ('id_front' in formValues) {
            errorRedirect = '/auth/register_scan_emirates_front'
            successRedirect =  savedFile.maxAttemptsWarning ? "/auth/write_yourself_fields" : '/auth/register_scan_emirates'
        }

        if (savedFile.isSuccessful && savedFile.scanDoc?.document?.valid) {
            if ('id_back' in formValues || 'visa' in formValues) {
                dispatch(scanSuccess({ ...savedFile.scanDoc.document, type: savedFile.scanDoc.type }))
            }
            return { textNavigate: successRedirect, isSuccessful: true, textNotification: '' }
        } else {
            return { ...savedFile , textNavigate:  savedFile.maxAttemptsWarning ?  successRedirect : errorRedirect  }
        //    return { textNavigate: errorRedirect, isSuccessful: false, textNotification: savedFile.textNotification ?? 'Some error occurred,please try again' }
        }
        
    } catch (error) {
        console.log(error)
        return { textNavigate: errorRedirect, isSuccessful: false, textNotification: 'Some error occurred,please try again' }
    }
}
