import { type AppDispatch, type RootState } from '../store'
import { ACCESS_KEY, USER_ID_KEY, urlAPI } from '../../urls'

import { type ApiResponse } from '../../types/userTypes/TAuth'
import getChatAdapter from '../../functions/adapters/chat/getChatAdapter'
import {
    chatSetInvitePageCount,
    chatSetPageCount,
    setChat,
    setCurrentFile,
    setCurrentMessage,
    setCurrentUnit,
    setMessages, setPendingMessages, setSearchInvites, setSearchMessages, setUnread
} from '../slices/chatSlice'
import getMessageAdapter, { getSingleMessageAdapter } from '../../functions/adapters/chat/getMessageAdapter'
import getChatPageCount from '../../functions/adapters/chat/getChatPageCount'
import _, { isNumber } from 'lodash'
import { type IChatUnread, type IChatMessage } from '../../types'
import { MESSAGE_UNPUBLISH_TIME } from '../../constants'
import ChatUtils from '../../functions/chats/ChatUtils'
import { setPageCount, setPaginationLoading } from '../slices/paginationSlice'
import qs from 'qs'
import customLog from '../../functions/log'
import Swal from 'sweetalert2'
import { swalOptions } from '../../functions/swalOptions'
import { getChatList } from './chatListActions'
import checkDocumentsForOtherUser from '../../functions/agent/documents/checkDocumentsForOtherUser'
import { removeDataAttributes } from '../../functions/adapters/universal/removeDataAttributes'
import { singleChatAdapterForList, sortChatsForChatList } from '../../functions/adapters/chatList/getChatListAdapter'
import { setChatList } from '../slices/chatListSlice'
import { removeNotifyByDeviceToken } from '../../functions/shared/notify-helpers/removeNotify'

export const messagePopulate = {
    Sender: {
        populate: ['ContactInfo']
    },
    ChatRoom: {
        populate: {
            Users: {
                populate: ['ContactInfo']
            }
        }
    },
    Unit: {
        populate: ['Property', 'Marketing', 'User', 'Documents']
    }
}

const chatPopulate = {
    Users: {
        populate: ['ContactInfo', 'Type', 'BlackList']
    },
    LastMessage: {
        populate: ['Sender']
    }
}

const chatQuery = 'populate[Users][populate][0]=ContactInfo,Type,BlackList&populate=LastMessage'

export const getChat = (chatID: number, page: number, messageID?: number, triggeredBySocket: boolean = false, messagesCallback?: () => void) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''

        const bodyObject = {
            method: 'GET',
            collection: 'chat-rooms',
            id: chatID,
            query: chatQuery // &populate[Messages][populate][0]=Sender,Unit
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })

        const responseJSON = await response.json() as unknown as ApiResponse
        if (responseJSON.success) {
            if (responseJSON.response.error != null) {
                // error handling
            } else {
                if (
                    triggeredBySocket &&
                    (responseJSON as any)?.response?.data?.attributes?.LastMessage?.data?.id !== getState().chat.chat.LastMessage?.id
                ) {
                    void dispatch(getWebsocketMessageForSearch({
                        id: (responseJSON as any)?.response?.data?.attributes?.LastMessage?.data?.id,
                        Text: (responseJSON as any)?.response?.data?.attributes?.LastMessage?.data?.attributes?.Text
                    }))
                }
                dispatch(
                    setChat(
                        getChatAdapter(responseJSON, +userID)
                    )
                )
            }
        }
        await dispatch(getMessages(chatID, page, messageID, messagesCallback))
    } catch (error) {
        console.log(error)
    }
}

export const getMessages = (chatID: number, page: number, id?: number, callback?: () => void) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setPaginationLoading(true))
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const query: any = {
            sort: {
                id: 'desc'
            },
            populate: messagePopulate,
            filters: {
                ChatRoom: {
                    id: chatID
                },
                id: id != null ? id : undefined
            },
            pagination: {
                page,
                pageSize: 20,
                withCount: true
            }
        }

        //  console.log(query)

        const bodyObject = {
            method: 'GET',
            collection: 'messages',
            query: qs.stringify(query, {
                encodeValuesOnly: true
            })
            // query: `sort=id:desc&filters[ChatRoom][id][0]=${chatID}&populate[Unit][populate]=Property,Marketing&[populate]=Sender&pagination[page]=${page}&pagination[pageSize]=20&pagination[withCount]=true`
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json() as unknown as ApiResponse
        //  console.log(responseJSON)
        if (responseJSON.success) {
            const pageCount = getChatPageCount(responseJSON)
            if (responseJSON.response.error != null) {
                // error handling
            } else {
                if (callback != null) {
                    callback()
                }

                const fetchedMessages = getMessageAdapter(responseJSON)
                const storedMessages = getState().chat.Messages ?? []
                if (page === 1) {
                    _.remove(fetchedMessages, (message) =>
                        _.some(storedMessages, (storedMessage) => storedMessage.id === message.id)
                    )
                    const messages = [...fetchedMessages, ...storedMessages]
                    dispatch(setMessages(messages))
                    dispatch(setPageCount(pageCount))
                } else {
                    const messages = [...storedMessages, ...fetchedMessages]
                    dispatch(setMessages(messages))
                    dispatch(setPageCount(pageCount))
                }
            }
        }
    } catch (error) {
        console.error(error)
    }
    dispatch(setPaginationLoading(false))
}

export const getSharedMedia = (chatID: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''

        const query: any = {
            sort: {
                id: 'desc'
            },
            populate: messagePopulate,
            filters: {
                text: {
                    $in: [
                        'type:audio',
                        'type:voice',
                        'type:video',
                        'type:file'
                    ]
                },
                ChatRoom: {
                    id: chatID
                }
            }
        }

        const bodyObject = {
            method: 'GET',
            collection: 'messages',
            query: qs.stringify(query, {
                encodeValuesOnly: true
            })
            // query: `sort=id:desc&filters[ChatRoom][id][0]=${chatID}&populate[Unit][populate]=Property,Marketing&[populate]=Sender&pagination[page]=${page}&pagination[pageSize]=20&pagination[withCount]=true`
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json() as unknown as ApiResponse
        //  console.log(responseJSON)
        if (responseJSON.success) {
            if (responseJSON.response.error != null) {
                // error handling
            } else {
                dispatch(setMessages(getMessageAdapter(responseJSON)))
            }
        }
    } catch (error) {
        console.error(error)
    }
}

export const sendMessage = (chatID: number, lifeTime?: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const message = getState().chat.currentMessage
        dispatch(setCurrentMessage(''))
        const bodyObject: Record<string, any> = {
            method: 'POST',
            collection: 'messages',
            body: {
                Text: message,
                Attachments: [getState().chat.currentFile],
                Sender: {
                    id: getState().user.currentUser.id
                },
                Unit: {
                    id: getState().chat.currentUnit
                },
                ChatRoom: {
                    id: chatID // getState().chat.chat.id
                }
            },
            query: qs.stringify({
                populate: messagePopulate
            })
        }

        if (lifeTime != null) {
            bodyObject.body.mode = 'unpublish'
            bodyObject.body.executeAt = new Date().getTime() + lifeTime
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json() as unknown as ApiResponse
        if (responseJSON.success) {
            customLog(responseJSON, 'SENT MESSAGE')
            if (responseJSON?.response.error != null) {
                // error handling
            } else {
                void dispatch(updateLastMessage(chatID, responseJSON.response.data.id))
                // await dispatch(getMessages(chatID, 1))
                // await dispatch(addUnreadMessage(chatID))
                const message = getSingleMessageAdapter(responseJSON.response.data)
                dispatch(setCurrentFile(null))
                dispatch(setCurrentUnit(null))
                dispatch(setMessages([message, ...getState().chat.Messages]))
            }
        }
    } catch (error) {
        console.error(error)
    }
}

interface OpenSupportTicketParams {
    chatID: number,
    type: 'viewing' | 'offer' | 'advertise' | 'profile' | 'units' | 'other',
    isOpen: true
    isClose?: false
}

interface CloseSupportTicketParams {
    chatID: number,
    isClose: true
    isOpen?: false
}

export const handleSupportTicket = (params: OpenSupportTicketParams | CloseSupportTicketParams) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''

        const userID = getState().user.currentUser.id
        if (!isNumber(userID)) {
            return
        }
        const supportTickets = _.cloneDeep(getState().chat.chat.SupportTickets) ?? []
        if (params.isOpen === true) {
            supportTickets.push({
                TicketOpen: true,
                TicketType: params.type,
                MessageIds: []
            })
        } else {
            supportTickets[supportTickets.length - 1].TicketOpen = false
        }

        const bodyObject = {
            method: 'PUT',
            collection: 'chat-rooms',
            id: params.chatID,
            body: {
                SupportTickets: supportTickets
            },
            query: qs.stringify({
                populate: chatPopulate
            }, { encodeValuesOnly: true })
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json()
        const chat: Record<string, any> = responseJSON.response.data
        dispatch(chatRoomFetchingSuccess(chat))
        const id = getState().chat.pendingMessages.length
        if (params.isOpen === true) {
            dispatch(setPendingMessage(`type:open-issue-${params.type}`, id))
            dispatch(setCurrentMessage(`type:open-issue-${params.type}`))
        } else {
            dispatch(setPendingMessage('type:close-issue', id))
            dispatch(setCurrentMessage('type:close-issue'))
        }
        dispatch(sendMessage(+params.chatID)).finally(() => {
            dispatch(removePendingMessage(id))
        })
    } catch (error) {
        console.error(error)
    }
}

export const broadcastUnit = (unitID: number, callback?: (broadcastID: number) => void) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    // const { currentUser } = getState().user
    // console.log(currentUser.Chats)
    // const broadcast: IChatListItem = _.find(currentUser.Chats, ChatUtils.isBSOBroadcast)
    dispatch(setCurrentMessage('type:unit'))
    dispatch(setCurrentUnit(unitID))
    await dispatch(sendMessage(Number(process.env.REACT_APP_BSO_BROADCAST_ID ?? '4'), MESSAGE_UNPUBLISH_TIME))
    if (callback != null) {
        callback(Number(process.env.REACT_APP_BSO_BROADCAST_ID ?? '4'))
    }
}

export const updateLastMessage = (chatID: number, messageID: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''

        const userID = getState().user.currentUser.id
        if (!isNumber(userID)) {
            return
        }
        // const currentUnread = getState().chat.chat?.Unread?.[`${userID}`] ?? 0
        // if (!isNumber(currentUnread)) {
        //     return
        // }
        const storedChat = _.cloneDeep(getState().chat.chat)
        const Unread: IChatUnread = _.cloneDeep(getState().chat.chat?.Unread ?? {})
        storedChat.UserIDs?.forEach((userID) => {
            if (userID !== getState().user.currentUser.id) {
                Unread[String(userID)] = (Unread[String(userID)] ?? 0) + 1
            }
        })
        dispatch(setUnread(Unread))
        const supportTickets = storedChat?.SupportTickets ?? []
        if (supportTickets?.length > 0 && supportTickets[supportTickets.length - 1].TicketOpen) {
            supportTickets[supportTickets.length - 1].MessageIds.push(messageID)
        }

        const bodyObject = {
            method: 'PUT',
            collection: 'chat-rooms',
            id: chatID,
            body: {
                LastMessage: {
                    id: messageID
                },
                SupportTickets: supportTickets,
                Unread
            },
            query: qs.stringify({
                populate: chatPopulate
            }, { encodeValuesOnly: true })
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json()
        const chat: Record<string, any> = responseJSON.response.data
        dispatch(chatRoomFetchingSuccess(chat))
    } catch (error) {
        console.error(error)
    }
}

export const removeUnreadMessages = (chatID: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const userID = getState().user.currentUser.id
        if (!isNumber(userID)) {
            return
        }
        const Unread: IChatUnread = { ...(getState().chat.chat?.Unread ?? {}) }

        if (Unread[String(userID)] === 0 || Unread[String(userID)] == null) {
            return
        } else {
            Unread[String(userID)] = 0
        }

        const bodyObject = {
            method: 'PUT',
            collection: 'chat-rooms',
            id: chatID,
            body: {
                Unread
            },
            query: chatQuery
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json()
        const chat: Record<string, any> = responseJSON.response.data
        dispatch(chatRoomFetchingSuccess(chat))
        await removeNotifyByDeviceToken({
            userID: getState().user.currentUser.id as number,
            deviceToken: getState().user.currentUser.DeviceToken as string
        })
    } catch (error) {
        console.error(error)
    }
}

export const setPendingMessage = (text: string, id: number, name?: string, data?: string, size?: number, type?: string, duration?: number) => (dispatch: AppDispatch, getState: () => RootState) => {
    const message: IChatMessage = {
        id,
        Sender: -1,
        Text: text,
        updatedAt: new Date().toISOString(),
        Attachments: {
            name: name ?? 'Pending...',
            data: data ?? '',
            size: size ?? 0,
            type: type ?? '',
            duration: duration ?? 0
        },
        Unit: {
            attributes: {
                Property: {
                    Name: 'Pending...'//,
                    // Price: 'Pending'
                }
            }
        }
    }
    const pendingMessages = getState().chat.pendingMessages
    dispatch(setPendingMessages([message, ...pendingMessages]))
}

export const removePendingMessage = (id: number) => (dispatch: AppDispatch, getState: () => RootState) => {
    const pendingMessages = [...getState().chat.pendingMessages]
    _.remove(pendingMessages, (message) => message.id === id)
    dispatch(setPendingMessages(pendingMessages))
}

export const removeAllPendingMessages = () => (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setPendingMessages([]))
}

export const searchMessages = (page: number, invites: boolean | undefined = false, callback?: () => void) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    dispatch(setPaginationLoading(true))

    try {
        if (getState().chat.searchString === '') {
            dispatch(setSearchMessages([]))
            dispatch(setPaginationLoading(false))
            return
        }
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''

        const query = {
            sort: {
                id: 'desc'
            },
            populate: messagePopulate,
            filters:
            !invites
                ? {
                    $and: [
                        getState().chat.searchString !== ''
                            ? {
                                Text: {
                                    $contains: getState().chat.searchString
                                }
                            }
                            : undefined,
                        {
                            $not: {
                                Text: {
                                    $in: ['type:voice', 'type:file', 'type:unit', 'type:video', 'type:audio']
                                }
                            }
                        },
                        {
                            ChatRoom: {
                                id: {
                                    $in: _.map(getState()?.chatList?.chatList, (chat: any) => chat.id)
                                }
                            }
                        },
                        {
                            Sender: {
                                id: {
                                    $notNull: true
                                }
                            }
                        }
                    ]
                }
                : {
                    $and: [
                        {
                            Text: {
                                $eq: 'type:unit'
                            }
                        },
                        getState().chat.searchString !== ''
                            ? {
                                Unit: {

                                    Property: {
                                        Area: {
                                            $contains: getState().chat.searchString
                                        }
                                    }

                                }
                            }
                            : undefined,
                        {
                            ChatRoom: {
                                id: {
                                    $in: _.map((getState()?.user?.currentUser as any)?.Chats, (chat: any) => chat.id)
                                }
                            }
                        }
                    ]
                },
            pagination: {
                page,
                pageSize: 20,
                withCount: true
            }
        }

        const bodyObject = {
            method: 'GET',
            collection: 'messages',
            query: qs.stringify(query, {
                encodeValuesOnly: true
            })
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json() as unknown as ApiResponse
        //   console.log(responseJSON)
        if (responseJSON.success) {
            const pageCount = getChatPageCount(responseJSON)
            if (responseJSON.response.error != null) {
                // error handling
            } else {
                if (callback != null) {
                    callback()
                }
                const fetchedMessages = getMessageAdapter(responseJSON)
                const storedMessages = getState().chat.searchedMessages ?? []
                const messages = [...storedMessages, ...fetchedMessages]
                dispatch(setSearchMessages(messages))
                dispatch(chatSetPageCount(pageCount))
            }
        }
    } catch (error) {
        console.error(error)
    }
    dispatch(setPaginationLoading(false))
}

export const searchInvites = (page: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    dispatch(setPaginationLoading(true))
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''

        const query = {
            sort: {
                id: 'desc'
            },
            populate: messagePopulate,
            filters:
                 {
                     $and: [
                         {
                             Text: {
                                 $eq: 'type:unit'
                             }
                         },
                         getState().chat.invitesSearchString !== ''
                             ? {
                                 Unit: {

                                     Property: {
                                         Area: {
                                             $contains: getState().chat.searchString
                                         }
                                     }

                                 }
                             }
                             : undefined,
                         {
                             ChatRoom: {
                                 id: {
                                     $in: _.map((getState()?.user?.currentUser as any)?.Chats, (chat: any) => chat.id)
                                 }
                             }
                         }
                     ]
                 },
            pagination: {
                page,
                pageSize: 20,
                withCount: true
            }
        }

        const bodyObject = {
            method: 'GET',
            collection: 'messages',
            query: qs.stringify(query, {
                encodeValuesOnly: true
            })
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json() as unknown as ApiResponse
        if (responseJSON.success) {
            const pageCount = getChatPageCount(responseJSON)
            if (responseJSON.response.error != null) {
                // error handling
            } else {
                const fetchedMessages = getMessageAdapter(responseJSON)
                const storedMessages = getState().chat.searchedInvites ?? []
                const messages = [...storedMessages, ...fetchedMessages]
                dispatch(setSearchInvites(messages))
                dispatch(chatSetInvitePageCount(pageCount))
            }
        }
    } catch (error) {
        console.error(error)
    }
    dispatch(setPaginationLoading(false))
}

export const getWebsocketMessageForSearch = (entry: Record<string, unknown>) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    customLog('SEARCH MESSAGES')
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const isInvite = entry.Text === 'type:unit'

        const query = {
            sort: {
                id: 'desc'
            },
            populate: messagePopulate,
            filters:
                !isInvite
                    ? {
                        $and: [
                            {
                                id: entry.id
                            },
                            getState().chat.searchString !== ''
                                ? {
                                    Text: {
                                        $contains: getState().chat.searchString
                                    }
                                }
                                : undefined,
                            {
                                $not: {
                                    Text: {
                                        $in: ['type:voice', 'type:file', 'type:unit', 'type:video', 'type:audio']
                                    }
                                }
                            }
                        ]
                    }
                    : {
                        $and: [
                            {
                                id: entry.id
                            },
                            {
                                Text: {
                                    $eq: 'type:unit'
                                }
                            },
                            getState().chat.searchString !== ''
                                ? {
                                    Unit: {

                                        Property: {
                                            Area: {
                                                $contains: getState().chat.searchString
                                            }
                                        }

                                    }
                                }
                                : undefined
                        ]
                    }
        }

        const bodyObject = {
            method: 'GET',
            collection: 'messages',
            query: qs.stringify(query, {
                encodeValuesOnly: true
            })
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json() as unknown as ApiResponse
        //   console.log(responseJSON)
        if (responseJSON.success) {
            // const pageCount = getChatPageCount(responseJSON)
            if (responseJSON.response.error != null) {
                // error handling
            } else {
                const fetchedMessages = getMessageAdapter(responseJSON)
                if (isInvite) {
                    const storedMessages = getState().chat.searchedInvites ?? []
                    if (!_.some(storedMessages, (message: any) => message.id === entry.id)) {
                        const messages = [...fetchedMessages, ...storedMessages]
                        dispatch(setSearchInvites(messages))
                    }
                } else {
                    const storedMessages = getState().chat.searchedMessages ?? []
                    if (!_.some(storedMessages, (message: any) => message.id === entry.id)) {
                        const messages = [...fetchedMessages, ...storedMessages]
                        dispatch(setSearchMessages(messages))
                    }
                }

                // dispatch(chatSetPageCount(pageCount))
            }
        }
    } catch (error) {
        console.error(error)
    }
}

export const openChatByUserID = (userID: number, currentUserID?: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<{ id: number | null }> => {
    try {
        await dispatch(getChatList())

        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        // const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
        const { currentUser } = getState().user
        const chats = getState().chatList.chatList
        const chat = _.find(chats,
            (chatItem) => chatItem.userID === userID && !ChatUtils.isBSOBroadcast(chatItem)
        )
        //   console.log({ chat })
        if (chat != null) {
            return {
                id: chat.id
            }
        } else {
            // const userStatuses = await dispatch(checkUserDocuments(userID))
            const user = getState().user.allUsers.find(user => user.id === userID)
            // const statuses = getAgentStatuses(user?.Documents ?? [])
            const documentsValid = checkDocumentsForOtherUser(userID)
            if ((documentsValid && user?.emailVerified === true) || userID === Number(process.env.REACT_APP_BSO_USER_ID ?? '57')
            /* || userID === Number(process.env.REACT_APP_BSO_SUPPORT_USER_ID ?? '260') || */
            ) {
                const bodyObject = {
                    method: 'POST',
                    collection: 'chat-rooms',
                    body: {
                        Users: [
                            currentUserID ?? currentUser.id,
                            userID
                        ]
                    }
                    // query: 'populate[Users][populate][0]=Type' // &populate[Messages][populate][0]=Sender,Unit
                }
                const response = await fetch(`${urlAPI}`, {
                    method: 'POST',
                    body: JSON.stringify(bodyObject),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    }
                })
                const responseJSON = await response.json() as unknown as ApiResponse
                //     console.log(responseJSON)
                if (responseJSON.success) {
                    return {
                        id: responseJSON.response.data.id
                    }
                } else {
                    return {
                        id: null
                    }
                }
            } else {
                await Swal.fire({
                    ...swalOptions('error'),
                    ...{
                        title: 'User you are trying to contact is not yet verified',
                        html: 'Try again later'
                    }
                })
                return {
                    id: null
                }
            }
        }
    } catch (e) {
        console.error(e)
        return {
            id: null
        }
    }
}

export const chatRoomFetchingSuccess = (chat: Record<string, any>) => (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const chatList = getState().chatList.chatList.filter(chatItem => chatItem.id !== chat.id)
        const processedChat = singleChatAdapterForList(Number(getState().user.currentUser.id))(removeDataAttributes(_.cloneDeep(chat)))
        const chats = sortChatsForChatList([...chatList, processedChat])
        dispatch(
            setChatList(
                chats
            )
        )
        if (getState().chat.chat?.id != null) {
            console.log({chat})
            const currentChat= getChatAdapter({ response: { data: chat } }, Number(getState().user.currentUser.id))
            console.log({currentChat})
            dispatch(
                setChat(
                    currentChat
                )
            )
        }
    } catch (err) {
        console.log(err)
    }
}
