import { getErrors } from '../../functions/errors/GetErrors'
import { ACCESS_KEY, USER_ID_KEY, urlAPI } from '../../urls'
import {
    setSearchAreasFound, setSearchNoAreasFound, setSearchSearchFilters, setUnitsSearchCatalog,
    type Unit,
    type UnitsCategory,
    unitsSetCatalogLoading,
    unitsSetSearchCatalogLoading,
    unitsSlice
} from '../slices/unitsSlice'
import { type AppDispatch, type RootState, store } from '../store'

import _ from 'lodash'
import { type IFile } from '../../types'
import qs from 'qs'

import { type User, userFetchingSuccess } from '../slices/userSlice'

import { type ApiResponse } from '../../types/userTypes/TAuth'
import { dataFetchingFinish, dataFetchingStart } from '../slices/modalSlice'
import { setPageCount, setPaginationLoading } from '../slices/paginationSlice'
import customLog from '../../functions/log'
import nanToNull from '../../functions/adapters/universal/nanToNull'
import { nanoid } from 'nanoid'
import { uploadMultipleFiles, uploadStrapiFile } from './fileActions'
import { fetchUser } from './userActions'
import { rerenderModal } from '../slices/setUnitSlice'
import notifyDelay from '../../functions/shared/notify-helpers/notifyDelay'
import { NOTIFY_DELAY, UNIT_SOLD_NOTIFY_DELAY } from '../../constants'

import dayjs from 'dayjs'
import { sendNotification } from './offerActions'
import { removeDataAttributes } from '../../functions/adapters/universal/removeDataAttributes'
import Swal from 'sweetalert2'
import { swalOptions } from '../../functions/swalOptions'
import { type NavigateFunction } from 'react-router-dom'
import { processPropertyDeleteTasks } from './propertyActions'
import { getQueryUnits, unitsPopulate } from '../../functions/shared/api/queries/getQueryUnits'
import { createDocument } from './documentsActions/createDocument'
import {updateDocument} from "./documentsActions/updateDocument";
import { getChatList } from './chatListActions'

// const unitsQuery = '?populate[KeysBookings]=*populate[ParkingSlots]=*&populate[Marketing]=*&populate[Utilities]=*&populate[User][fields][0]=id&populate[Documents][fields][0]=id&populate[Property][populate][Utilities]=*&populate[Viewings][populate][User][fields][0]=id[populate][Unit][fields][0]=id&populate[Offers][populate][Type]=*&populate[Offers][populate][User][fields][0]=id&populate[Advertises][populate][User][fields][0]=id&populate[Advertises][populate][Unit][fields][0]=id

export const fetchUnits = (noLading?: boolean) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    // const userID = window.localStorage.getItem(USER_ID_KEY)
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        if (noLading !== true) {
            dispatch(unitsSlice.actions.unitsFetching())
        }
        try {
            const bodyObject = {
                method: 'GET',
                collection: 'units',
                query: getQueryUnits()// body: {}
            }

            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                },
                body: JSON.stringify(bodyObject)
            })

            //     console.log(response, 'response()^&&??????????????????????')
            const responseJSON = await response.json()
            //   console.log(responseJSON, 'responseJSON___________ascasc____????????????')

            if (responseJSON.success) {
                if (responseJSON.response.error != null) {
                    dispatch(unitsSlice.actions.unitsFetchingError(getErrors(responseJSON)))
                    return { ...responseJSON, textNavigate: '', isSuccessful: false, textNotification: getErrors(responseJSON) }
                } else {
                    dispatch(unitsSlice.actions.unitsFetchingSuccess(responseJSON?.response?.data))
                    return { ...responseJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
                }
            } else {
                dispatch(unitsSlice.actions.unitsFetchingError(getErrors(responseJSON)))
                return { textNavigate: '', isSuccessful: false, textNotification: '' }
            }
        } catch (error) {
            console.log(error)
            dispatch(unitsSlice.actions.unitsFetchingError(JSON.stringify(error)))
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
            // dispatch(unitsSlice.actions.unitsFetchingError(error as Error))
        }
    }
}

export const fetchCatalogUnits = (page: number, category: UnitsCategory, secondCall: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    customLog('no area found', getState().units.noAreasFound)
    customLog('pagination loading', getState().pagination.paginationLoading)
    if (getState().units.noAreasFound || (getState().units.catalogLoading && !secondCall)) {
        return
    }
    const areasFound = getState().units.areasFound
    dispatch(unitsSetCatalogLoading(true))
    try {
        const queries = {
            rent: {
                sort: ['createdAt'],
                populate: unitsPopulate,
                filters: {
                    $and: [
                        {
                            $or: [
                                {
                                    User: {
                                        id: {
                                            $eq: +(process.env.REACT_APP_BSO_USER_ID ?? '57')
                                        }
                                    }
                                },
                                {
                                    $and: [
                                        {
                                            User: {
                                                Category: {
                                                    $eq: 'Landlord'
                                                }
                                            }
                                        },
                                        {
                                            Marketing: {
                                                Status: {
                                                    $eq: 'Enabled'
                                                }
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            Marketing: {
                                Use: {
                                    $eq: 'For rent'
                                }
                            }
                        }
                    ]
                },
                pagination: {
                    page,
                    pageSize: 5,
                    withCount: true
                }
            },
            sell: {
                sort: ['createdAt'],
                populate: unitsPopulate,
                filters: {
                    $and: [
                        {
                            $or: [
                                {
                                    User: {
                                        id: {
                                            $eq: +(process.env.REACT_APP_BSO_USER_ID ?? '57')
                                        }
                                    }
                                },
                                {
                                    $and: [
                                        {
                                            User: {
                                                Category: {
                                                    $eq: 'Landlord'
                                                }
                                            }
                                        },
                                        {
                                            Marketing: {
                                                Status: {
                                                    $eq: 'Enabled'
                                                }
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            Marketing: {
                                Use: {
                                    $eq: 'For sale'
                                }
                            }
                        }
                    ]
                },
                pagination: {
                    page,
                    pageSize: 5,
                    withCount: true
                }
            },
            all: {
                sort: {
                    createdAt: 'desc'
                }, // ['createdAt:desc'],
                populate: unitsPopulate,
                filters: {
                    $or: [
                        {
                            User: {
                                id: {
                                    $eq: +(process.env.REACT_APP_BSO_USER_ID ?? '57')
                                }
                            }
                        },
                        {
                            $and: [
                                {
                                    User: {
                                        Category: {
                                            $eq: 'Landlord'
                                        }
                                    }
                                },
                                {
                                    Marketing: {
                                        Status: {
                                            $eq: 'Enabled'
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                },
                pagination: {
                    page,
                    pageSize: 5,
                    withCount: true
                }
            }
        }

        let query: Record<string, any>

        if (!areasFound) {
            query = dispatch(applyUnitsFilters({ ...queries[category === "rent" || category === "sell" ? category : "all" ] }, {
                Property: {
                    Area: {
                        $in: (getState().user.currentUser as User)?.Settings?.[0]?.Areas ?? []
                    }
                }
            }))
        } else {
            query = dispatch(applyUnitsFilters({ ...queries[category === "rent" || category === "sell" ? category : "all" ] }, {
                Property: {
                    Area: {
                        $not: {
                            $in: (getState().user.currentUser as User)?.Settings?.[0]?.Areas ?? []
                        }
                    }
                }
            }))
            if (getState().units.catalog?.length >= 5) {
                query.pagination.page = /* Math.max( */query.pagination.page - getState().units.areasPageCount/*, 1) */ + 1
            }
        }

        //  console.log({ query })

        const bodyObject = {
            method: 'GET',
            collection: 'units',
            query: qs.stringify(query, {
                encodeValuesOnly: true
            })
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(bodyObject)
        })

        const responseJSON = await response.json()
        // console.log(responseJSON, 'responseJSON')
        // dispatch(setPageCount(noAreaCount + areaCount))
        if (responseJSON.success === true) {
            const fetchedUnits = responseJSON.response.data as Unit[]
            const storedUnits = getState().units.catalog ?? []
            if (fetchedUnits?.length === 0) {
                dispatch(unitsSlice.actions.unitsSetPage(getState().units.page - 1))
            }
            // if (page === 1) {
            _.remove(fetchedUnits, (unit) =>
                _.some(storedUnits, (storedUnit) => storedUnit.id === unit.id)
            )
            const units = [...storedUnits, ...fetchedUnits]
            dispatch(unitsSlice.actions.unitsSetCatalog(units))
            if (!getState().units.areasFound) {
                dispatch(unitsSlice.actions.unitsSetAreasPageCount(responseJSON.response.meta.pagination.pageCount))
            }
            // } else {
            //    const units = [...storedUnits, ...fetchedUnits]
            //    dispatch(unitsSlice.actions.unitsSetCatalog(units))
            // dispatch(unitsSlice.actions.unitsSetPageCount(responseJSON.response.meta.pagination.pageCount))
            // }
            if (fetchedUnits.length < 5) {
                if (!getState().units.areasFound) {
                    dispatch(unitsSetCatalogLoading(false))
                    dispatch(unitsSlice.actions.unitsSetAreasFound(true))
                    await dispatch(fetchCatalogUnits(getState().units.page, category, true))
                } else {
                    dispatch(unitsSlice.actions.unitsSetNoAreasFound(true))
                }
            }
            /* if (fetchedUnits.length < 5 && !areasFound) {
                dispatch(unitsSlice.actions.unitsSetAreasFound(true))
                // dispatch(unitsSlice.actions.unitsSetAreasPageCount(responseJSON.response.meta.pagination.pageCount))
                await dispatch(fetchCatalogUnits(page + 1, category, true))
            } else if (fetchedUnits.length < 5 && !getState().units.noAreasFound) {
                dispatch(unitsSlice.actions.unitsSetNoAreasFound(true))
            } */
        } else {
            // dispatch(unitsSlice.actions.unitsFetchingError(getErrors(responseJSON)))
            throw (new Error())
        }
    } catch (err) {
        console.error(err)
    }
    dispatch(unitsSetCatalogLoading(false))
}

export const fetchSearchCatalogUnits = (page: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    // console.log('no area found', getState().units.searchNoAreasFound)
    if (getState().units.noAreasFound || getState().units.searchCatalogLoading) {
        return
    }
    const areasFound = getState().units.searchAreasFound
    dispatch(unitsSetSearchCatalogLoading(true))
    try {
        const searchQuery = {
            sort: {
                createdAt: 'desc'
            }, // ['createdAt:desc'],
            populate: unitsPopulate,
            filters: {
                $or: [
                    {
                        User: {
                            id: {
                                $eq: +(process.env.REACT_APP_BSO_USER_ID ?? '57')
                            }
                        }
                    },
                    {
                        $and: [
                            {
                                User: {
                                    Category: {
                                        $eq: 'Landlord'
                                    }
                                }
                            },
                            {
                                Marketing: {
                                    Status: {
                                        $eq: 'Enabled'
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            pagination: {
                page,
                pageSize: 5,
                withCount: true
            }
        }

        let query: Record<string, any>

        if (!areasFound) {
            query = dispatch(applySearchUnitsFilters(searchQuery, {
                Property: {
                    Area: {
                        $in: (getState().user.currentUser as User)?.Settings?.[0]?.Areas ?? []
                    }
                }
            }))
        } else {
            query = dispatch(applySearchUnitsFilters(searchQuery, {
                Property: {
                    Area: {
                        $not: {
                            $in: (getState().user.currentUser as User)?.Settings?.[0]?.Areas ?? []
                        }
                    }
                }
            }))
            if (getState().units.catalog?.length >= 5) {
                query.pagination.page = query.pagination.page - getState().units.searchAreasPageCount
            }
        }

        //  console.log({ query })

        const bodyObject = {
            method: 'GET',
            collection: 'units',
            query: qs.stringify(query, {
                encodeValuesOnly: true
            })
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(bodyObject)
        })

        const responseJSON = await response.json()
        // console.log(responseJSON, 'responseJSON')
        // dispatch(setPageCount(noAreaCount + areaCount))
        if (responseJSON.success === true) {
            const fetchedUnits = responseJSON.response.data as Unit[]
            const storedUnits = getState().units.searchCatalog ?? []
            if (page === 1) {
                _.remove(fetchedUnits, (unit) =>
                    _.some(storedUnits, (storedUnit) => storedUnit.id === unit.id)
                )
                const units = [...storedUnits, ...fetchedUnits]
                dispatch(setUnitsSearchCatalog(units))
            } else {
                const units = [...storedUnits, ...fetchedUnits]
                dispatch(setUnitsSearchCatalog(units))
                // dispatch(unitsSlice.actions.unitsSetPageCount(responseJSON.response.meta.pagination.pageCount))
            }
            if (fetchedUnits.length < 5) {
                if (!getState().units.searchAreasFound) {
                    dispatch(unitsSetSearchCatalogLoading(false))
                    dispatch(setSearchAreasFound(true))
                    void dispatch(fetchSearchCatalogUnits(page))
                } else {
                    dispatch(setSearchNoAreasFound(true))
                }
            }
            if (fetchedUnits.length < 5 && !areasFound) {
                dispatch(setSearchAreasFound(true))
                // dispatch(unitsSlice.actions.unitsSetAreasPageCount(responseJSON.response.meta.pagination.pageCount))
                await dispatch(fetchSearchCatalogUnits(page + 1))
            } else if (fetchedUnits.length < 5 && !getState().units.searchNoAreasFound) {
                dispatch(setSearchNoAreasFound(true))
            }
        } else {
            // dispatch(unitsSlice.actions.unitsFetchingError(getErrors(responseJSON)))
            throw (new Error())
        }
    } catch (err) {
        console.error(err)
    }
    dispatch(unitsSetSearchCatalogLoading(false))
}

export const applyUnitsFilters = (query: any, extraFilter: Record<string, any>) => (dispatch: AppDispatch, getState: () => RootState): Record<string, any> => {
    const filters = { ...getState().units.filters }
    const processedFilters: any = {
        $and: []
    }
    for (let key in filters) {
        let filter: any
        if (key[0] !== '$') {
            if (key[0] === '>') {
                filter = {
                    $in: filters[key]
                }
                key = key.slice(1)
            } else if (key[0] === '<') {
                filter = {
                    $contains: filters[key]
                }
                key = key.slice(1)
            } else if (_.isArray(filters[key])) {
                filter = {
                    $between: filters[key]
                }
            } else if (key.slice(0, 5) === 'FROM-') {
                filter = {
                    $gte: !_.includes([null, undefined, ''], filters[key]) ? Number(filters[key]) : undefined
                }
                key = key.slice(5)
            } else if (key.slice(0, 5) === 'UPTO-') {
                filter = {
                    $lte: !_.includes([null, undefined, ''], filters[key]) ? Number(filters[key]) : undefined
                }
                key = key.slice(5)
            } else if (filters[key] === 'More') {
                filter = {
                    $gt: 3
                }
            } else {
                filter = {
                    $eq: filters[key]
                }
            }
            const keySplit = key.split('_')
            _.reverse(keySplit)
            // console.log('keys')
            // console.log(keySplit)
            let processedFilter = _.reduce(keySplit, (accumulator, splitKey) => {
                const accumulatorCopy = { ...accumulator }
                accumulator = {}
                accumulator[splitKey] = accumulatorCopy
                return accumulator
            }, filter)
            //   console.log('filter', processedFilter)
            if (keySplit?.length > 1) {
                _.omit(processedFilters, [key])
            }
            if (_.includes(['Bathrooms', 'Bedrooms'], key) && processedFilter?.[key]?.$eq != null) {
                customLog(processedFilter, 'PROCESSED FILTER')
                processedFilter = {
                    $or: [
                        processedFilter,
                        {
                            [key]: {
                                $notNull: false
                            }
                        }
                    ]
                }
            }
            //   console.log(processedFilters)
            // processedFilters = { ...processedFilters, ...processedFilter }
            processedFilters.$and.push(processedFilter)
        } else {
            processedFilters.$and.push(filters[key])
            // processedFilters = { ...processedFilters, ...filters[key] }
        }
    }
    query.filters = {
        $and: [
            extraFilter,
            getState().units.searchFilters,
            processedFilters,
            query.filters
        ]
    }
    customLog('QUERY', query)
    return (query)
}

export const applySearchUnitsFilters = (query: any, extraFilter: Record<string, any>) => (dispatch: AppDispatch, getState: () => RootState): Record<string, any> => {
    query.filters = {
        $and: [
            extraFilter,
            getState().units.searchSearchFilters,
            query.filters
        ]
    }
    return (query)
}

export const applyUnitModalFilters = (data: Record<string, any>) => (dispatch: AppDispatch, getState: () => RootState) => {
    console.log(data)
    dispatch(setUnitsFilters({ ...getState().units.filters, ...data }))
    return ({
        textNotification: '',
        isSuccessful: true,
        textNavigate: -1
    })
}

export const applyUnitSearch = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const { searchString } = getState().units
    if (searchString?.length > 0 && getState().units.filters?.['>Property_Area']?.[0] == null) {
        const searchFilters = {
            /* Property: {
                Area: {
                    $contains: searchString.trim()
                }
            } */
            $or: [
                {
                    Property: {
                        $or: [
                            {
                                Area: {
                                    $contains: searchString.trim()
                                }
                            },
                            {
                                Name: {
                                    $contains: searchString.trim()
                                }
                            }
                        ]
                    }
                },
                !isNaN(+(searchString.trim()))
                    ? {
                        Number: {
                            $eq: +(searchString.trim())
                        }
                    }
                    : undefined
            ]
        }
        dispatch(unitsSlice.actions.unitsSetSearchFilters(searchFilters))
    } else {
        dispatch(unitsSlice.actions.unitsSetSearchFilters({}))
    }
}

export const applyUnitSearchSearch = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const { searchSearchString } = getState().units
    if (searchSearchString?.length > 0) {
        const searchFilters = {
            Property: {
                Area: {
                    $contains: searchSearchString.trim()
                }
            }
        }
        dispatch(setSearchSearchFilters(searchFilters))
    } else {
        dispatch(setSearchSearchFilters({}))
    }
}

export const setUnitsFilters = (payload: Record<string, any>) => unitsSlice.actions.unitsSetFilters(payload)

export const getLandlords = async (data: any, isRepresentative: boolean = false): Promise<Array<{ Landlord: number, Type: string, Representatives: number[], PowerOfAttorney: number | undefined }>> => {
    let representativeData: any = null
    if (data.representative?.ContactInfo?.Name != null && !isRepresentative) {
        representativeData = await getLandlords({ Owners: [data.representative] }, true)
    }
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const ownerPromises = _.map(data?.Owners?.filter(Boolean) as any[], async (owner, index) => {
        const names = _.split(owner.ContactInfo?.Name, ' ')
        const firstName = names[0]
        const familyName = _.join(_.drop(names), ' ')
        owner.ContactInfo.Email = owner?.ContactInfo?.Email === '' ? null : owner?.ContactInfo?.Email
        owner.ContactInfo.Phone = owner?.ContactInfo?.Phone === '' ? null : owner?.ContactInfo?.Phone
        /**
         * Find existing landlord
         */

        const query = {
            populate: ['ContactInfo'],
            filters: {
                ContactInfo: {
                    FirstName: {
                        $eq: firstName
                    },
                    FamilyName: {
                        $eq: familyName
                    }
                }
            }
        }

        const bodyObject = {
            method: 'GET',
            collection: 'landlords',
            query: qs.stringify(query, {
                encodeValuesOnly: true
            })
        }
        const landlordResponse = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const landlordResponseJSON = await landlordResponse.json()
        let landlordID: number
        if (landlordResponseJSON.response?.data?.[0]?.id != null) {
            /**
             * Use existing landlord
             */
            const editBodyObject = {
                method: 'PUT',
                collection: 'landlords',
                id: landlordResponseJSON.response?.data?.[0]?.id,
                body: {
                    RefID: [owner?.ContactInfo?.Name, nanoid()].join('-'),
                    ContactInfo: {
                        FirstName: firstName,
                        FamilyName: familyName,
                        ...owner?.ContactInfo
                    },
                    Documents: isRepresentative
                        ? [
                            store.getState().setUnit.representative.emirates_id,
                            store.getState().setUnit.representative.passport
                        ].filter(Boolean)
                        : [
                            store.getState().setUnit.owners[index]?.values?.emirates_id,
                            store.getState().setUnit.owners[index]?.values?.passport
                        ].filter(Boolean)
                }
            }
            const editLandlordResponse = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(editBodyObject),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                }
            })
            const newLandlordResponseJSON = await editLandlordResponse.json()
            landlordID = newLandlordResponseJSON?.response?.data?.id
        } else {
            /**
             * Create new landlord
             */
            const newBodyObject = {
                method: 'POST',
                collection: 'landlords',
                body: {
                    RefID: [owner?.ContactInfo?.Name, nanoid()].join('-'),
                    ContactInfo: {
                        FirstName: firstName,
                        FamilyName: familyName,
                        ...owner?.ContactInfo
                    },
                    Documents: isRepresentative
                        ? [
                            store.getState().setUnit.representative.emirates_id,
                            store.getState().setUnit.representative.passport
                        ].filter(Boolean)
                        : [
                            store.getState().setUnit.owners[index]?.values?.emirates_id,
                            store.getState().setUnit.owners[index]?.values?.passport
                        ].filter(Boolean)
                }
            }
            const newLandlordResponse = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(newBodyObject),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                }
            })
            const newLandlordResponseJSON = await newLandlordResponse.json()
            landlordID = newLandlordResponseJSON?.response?.data?.id
        }
        return {
            Landlord: landlordID,
            Representatives: [
                representativeData?.[0]?.Landlord
            ].filter(Boolean),
            PowerOfAttorney: representativeData != null ? store.getState().setUnit.representative.poa : undefined,
            Type: data.Owners?.length > 1 ? 'Owner of part of property' : 'Owner of property',
            IsDecisionMaker: owner.IsDecisionMaker === 'Yes'
        }
    })
    return await Promise.all(ownerPromises)
}

export const addUnit = (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    try {
        if (getState().modal.drawerOpen != null) {
            return {
                ignoreSubmit: true
            }
        }
        dispatch(dataFetchingStart())
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''

        // const uploadPromise = Promise.all(_.map(data.Images, async (file) => await uploadStrapiFile(file, 'properties')))
        const properties = getState().property.properties
        const property = properties?.find(property => property.id === data.Property)
        const images: Array<IFile | null> = getState().modal.images
        const storedImages = _.filter(images, (image) => !_.includes(image?.data, 'data:'))
        const notUploadedImages = _.filter(images, (image) => _.includes(image?.data, 'data:'))

        const uploadedImages = await Promise.all(_.map(notUploadedImages, async (image) => {
            const res: Response = await fetch(image?.data ?? '')
            const blob: Blob = await res.blob()
            const file = new File([blob], `unit-image-${nanoid()}`, { type: blob.type })
            return await uploadStrapiFile(file, 'properties', null, `unit-image-${nanoid()}`)
        }))

        data.title_deed = undefined

        data.RefID = `${String(data.Number)}-${String(property?.attributes?.Name)}-${String(property?.attributes?.Area)}-${nanoid()}`

        data.Images = _.map([...storedImages, ...uploadedImages], (image) => image?.data)

        data.User = getState().user.currentUser.id
        data.Documents = getState().modal.documents?.map(document => ({ id: document })) ?? []
        if (data.Bedrooms !== 'Studio') {
            data.Bedrooms = nanToNull(data.Bedrooms)
        } else {
            data.Bedrooms = 0
        }
        data.Bathrooms = nanToNull(data.Bathrooms)
        data.SizeArea = nanToNull(data.SizeArea)
        data.AdvertisePrice = nanToNull(data.AdvertisePrice) ?? 0
        data.Utilities.ACCount = nanToNull(data.Utilities.ACCount) ?? 0
        data.AdvertisementSpaces = data.AdvertisementSpaces === 'Open' ? null : nanToNull(data.AdvertisementSpaces)
        data.ManagedBy = 'BSO'
        data.Statuses = data.Statuses?.split('-') ?? []
        data.GuestBathroom = data.GuestBathroom === 'Yes'
        data.MaidsRoom = data.MaidsRoom === 'Yes'
        data.ImagesOnly = data.ImagesOnly === 'Yes'
        data.Landlords = await getLandlords(data)
        data.OccupiedUntil = data?.Statuses?.includes('Occupied') === true ? dayjs(data.OccupiedUntil).add(4, 'hours') : null
        data.DisabledTimes = (getState().setUnit.staticForm
            .find((rowItem) => rowItem.id === 'DisabledTimes')?.value ?? [])
            .map((range: any) => ({
                From: String(range.From) + ':00.000',
                To: String(range.To) + ':00.000'
            }))
        data.SpecialRequest = {}
        data?.tenant_requirements?.forEach((requirement: string) => {
            data.SpecialRequest[requirement] = true
        })
        if (getState().modal?.scannedData?.failedToScan?.url != null) {
            const response = await dispatch(createDocument(null, {
                RefID: `REF_DOC_title_deed_${new Date().getTime()}`,
                Type: [
                    {
                        __component: 'documents.title-deed',
                        Number: data.Scanned.DocumentNumber,
                        Year: nanToNull(data.Scanned.DocumentYear),
                        Type: data.property_type// data.Scanned.DocumentType
                    }
                ]
            }, undefined, undefined, getState()?.modal?.scannedData?.failedToScan?.url))
            if (response.isSuccessful) {
                data.Documents = [...data.Documents, { id: response?.response?.data?.id }]
            }
        }

        // TODO updateDocument
        const unitDocsIDs = data.Documents.map((doc: any) => doc.id)
        const unitTitleDeedDocs = getState().documents.documents.filter((doc) => unitDocsIDs.includes(doc.id) === true && doc?.attributes?.Type?.[0]?.__component === 'documents.title-deed')
        const titleDeedDoc = unitTitleDeedDocs.length > 0 ? unitTitleDeedDocs[unitTitleDeedDocs.length - 1] : undefined
        console.log(titleDeedDoc, data.property_type)
        if (titleDeedDoc != null && data.property_type !== titleDeedDoc?.attributes?.Type?.[0]?.Type) {
            await dispatch(updateDocument({
                id: titleDeedDoc.id,
                data: {
                    Type: [{
                        Type: data.property_type
                    }]
                }
            }))
        }

        // if (data.special_requests === 'Yes') {
        //     data.Statuses = Array.from(
        //         new Set(
        //             [...data.Statuses, 'Special Request']
        //         )
        //     )
        // } else {
        //     data.Statuses = data.Statuses.filter((status: string) => status !== 'Special Request')
        // }

        data = _.omit(data, ['Owners', 'Scanned'])

        const bodyObject = {
            method: 'POST',
            collection: 'units',
            body: data,
            query: getQueryUnits()
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })

        const responseJSON = await response?.json() as unknown as any
        responseJSON.isSuccessful = responseJSON?.response?.error == null
        responseJSON.textNotification = 'Unit is added successfully'
        responseJSON.textNavigate = -1
        dispatch(dataFetchingFinish())
        const { isSuccessful } = dispatch(singleUnitFetchingSuccess(responseJSON))
        if (!isSuccessful) {
            void dispatch(fetchUser(true))
            void dispatch(fetchUnits(true))
        }

        // sendNotification(notifyDelay(), getState().user.currentUser.id, )
        // void sendUnitNotification(responseJSON.response.data as Unit, 'user-unit-new')
        void dispatch(processPropertyDeleteTasks(Number(data.Property)))
        return responseJSON
    } catch (error) {
        dispatch(dataFetchingFinish())
        console.log(error)
        return {
            isSuccessful: false
        }
    }
}

export const updateUnit = (data: any, unitID: number, isMarketing: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    try {
        // console.log('IGNORE CONDITIONS', getState().modal.drawerOpen != null, !isMarketing)
        if (getState().modal.drawerOpen != null && (getState().modal.drawerOpen !== 'edit_unit_marketing_modal' || !getState().setUnit.changeDetected) && !isMarketing) {
            return {
                ignoreSubmit: true
            }
        }
        dispatch(dataFetchingStart())
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const unit = _.cloneDeep(getState().units.units?.find((unit) => unit.id === unitID))
        const images: Array<IFile | null> = getState().modal.images
        const storedImages = _.filter(images, (image) => !_.includes(image?.data, 'data:'))
        const notUploadedImages = _.filter(images, (image) => _.includes(image?.data, 'data:'))

        const uploadedImages = await Promise.all(_.map(notUploadedImages, async (image) => {
            const res: Response = await fetch(image?.data ?? '')
            const blob: Blob = await res.blob()
            const file = new File([blob], `unit-image-${nanoid()}`, { type: blob.type })
            return await uploadStrapiFile(file, 'units', String(unitID)/*, `unit-image-${nanoid()}` */)
        }))

        const additionalDocuments = data.AdditionalDocuments ?? []
        const storedDocuments = additionalDocuments.filter(_.isString) ?? []
        const uploadedDocuments = await Promise.all(_.map(additionalDocuments.filter((file: any) => !_.isString(file)), async (file) => {
            return await uploadStrapiFile(file, 'units', String(unitID)/*, `unit-additional-doc-${nanoid()}` */)
        }))

        data.User = getState().user.currentUser.id

        if (isMarketing) {
            data.Marketing.DiscountPrice = nanToNull(data?.Marketing?.DiscountPrice)
            // The first image will stay the first
            if (storedImages.includes(images[0])) {
                data.Images = _.map([...storedImages, ...uploadedImages], (image) => image?.data)
            } else {
                data.Images = _.map([...uploadedImages, ...storedImages], (image) => image?.data)
            }
            data.AdditionalDocuments = [
                ...storedDocuments,
                ...(uploadedDocuments.map(doc => doc?.data).filter(Boolean) ?? [])
            ]
            const hasMortgage = data.Statuses === 'Available'
            if (hasMortgage && unit?.attributes?.Statuses?.includes('Mortgage') === false) {
                data.Statuses = ['Mortgage', ...(unit?.attributes?.Statuses ?? [])]
            } else if (!hasMortgage && unit?.attributes?.Statuses?.includes('Mortgage') === true) {
                data.Statuses = unit?.attributes?.Statuses?.filter(status => status !== 'Mortgage')
            } else {
                data.Statuses = unit?.attributes?.Statuses ?? undefined
            }
            const unitSold = unit?.attributes?.Statuses?.includes('Sold') === true
            const formSold = data['sold-rented'] === 'Rented/Sold'
            const unitDrafted = unit?.attributes?.Statuses?.includes('Drafted') === true
            const formDrafted = data['sold-rented'] === 'Drafted'
            if (!unitSold && formSold) {
                data.Statuses = ['Sold', ...(data.Statuses ?? [])]
                data.Statuses = data.Statuses.filter((status: string) => status !== 'Drafted')
                data.HideDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24)
            } else if (unitSold && !formSold) {
                data.Statuses = data.Statuses.filter((status: string) => status !== 'Sold')
                data.HideDate = null
            }
            if (!unitDrafted && formDrafted) {
                data.Statuses = ['Drafted', ...(data.Statuses ?? [])]
                data.Statuses = data.Statuses.filter((status: string) => status !== 'Sold')
                data.HideDate = null
            } else if (unitDrafted && !formDrafted) {
                data.Statuses = data.Statuses.filter((status: string) => status !== 'Drafted')
                data.HideDate = null
            }
            data = _.omit(data, 'sold-rented')

            if (data.Video?.[0] != null && typeof data.Video !== 'string') {
                const video = await uploadStrapiFile(data.Video[0], 'properties', String(unitID), `unit-video-${nanoid()}`)
                data.Video = video?.data
            } else {
                data.Video = undefined
            }
            if (data.FloorPlan?.[0] != null && typeof data.FloorPlan !== 'string') {
                const floorPlan = await uploadStrapiFile(data.FloorPlan[0], 'properties', String(unitID), `unit-floor-${nanoid()}`)
                data.FloorPlan = floorPlan?.data
            } else {
                data.FloorPlan = undefined
            }
            if (data.Marketing.MarketingFormTemplate?.[0] != null) {
                const marketingFormTemplate = await uploadStrapiFile(
                    data.Marketing.MarketingFormTemplate?.[0],
                    'units',
                    String(unitID),
                    `unit-marketing-form-template-${nanoid()}`
                )
                data.Marketing.MarketingFormTemplate = marketingFormTemplate?.data ?? (unit?.attributes?.Marketing as any)?.MarketingFormTemplate
            } else {
                data.Marketing.MarketingFormTemplate = (unit?.attributes?.Marketing as any)?.MarketingFormTemplate
            }
            data.LegalNoticeServed = data.LegalNoticeServed === 'Yes'
            console.log('CurrentRent, AnnualServiceCharge', data.CurrentRent, ' - ', data.AnnualServiceCharge)
            if (_.isString(data?.CurrentRent) && data?.CurrentRent?.trim() === '') {
                data.CurrentRent = null
            } else {
                data.CurrentRent = nanToNull(Number(data.CurrentRent))
            }
            if (_.isString(data?.AnnualServiceCharge) && data?.AnnualServiceCharge?.trim() === '') {
                data.AnnualServiceCharge = null
            } else {
                data.AnnualServiceCharge = nanToNull(Number(data.AnnualServiceCharge)) ?? null
            }
        } else {
            data.title_deed = undefined

            if (data.Bedrooms !== 'Studio') {
                data.Bedrooms = nanToNull(data.Bedrooms)
            } else {
                data.Bedrooms = 0
            }
            data.Bathrooms = nanToNull(data.Bathrooms)
            data.SizeArea = nanToNull(data.SizeArea)
            data.AdvertisePrice = nanToNull(data.AdvertisePrice) ?? 0
            data.Utilities.ACCount = nanToNull(data.Utilities.ACCount) ?? 0
            data.AdvertisementSpaces = data.AdvertisementSpaces === 'Open' ? null : nanToNull(data.AdvertisementSpaces)

            data.GuestBathroom = data.GuestBathroom === 'Yes'
            data.MaidsRoom = data.MaidsRoom === 'Yes'
            data.ImagesOnly = data.ImagesOnly === 'Yes'
            data.OccupiedUntil = data?.Statuses?.includes('Occupied') === true ? dayjs(data.OccupiedUntil).add(4, 'hours') : null
            data.DisabledTimes = (getState().setUnit.staticForm
                .find((rowItem) => rowItem.id === 'DisabledTimes')?.value ?? [])
                .map((range: any) => ({
                    From: String(range.From) + ':00.000',
                    To: String(range.To) + ':00.000'
                }))

            data.SpecialRequest = {}
            data?.tenant_requirements?.forEach((requirement: string) => {
                data.SpecialRequest[requirement] = true
            })

            const hasMortgage = unit?.attributes?.Statuses?.includes('Mortgage') === true
            const unitSold = unit?.attributes?.Statuses?.includes('Sold') === true
            data.Statuses = hasMortgage
                ? ['Mortgage', ...data.Statuses.split('-')]
                : data.Statuses.split('-')
            if (unitSold) {
                data.Statuses = ['Sold', ...data.Statuses]
            }
            // if (data.special_requests === 'Yes') {
            //     data.Statuses = Array.from(
            //         new Set(
            //             [...data.Statuses, 'Special Request']
            //         )
            //     )
            // } else {
            //     data.Statuses = data.Statuses.filter((status: string) => status !== 'Special Request')
            // }

            data.Documents = getState().modal.documents?.map(document => ({ id: document })) ?? []

            data.Landlords = await getLandlords(data)

            if (getState().modal?.scannedData?.failedToScan?.url != null) {
                const response = await dispatch(createDocument(null, {
                    RefID: `REF_DOC_title_deed_${new Date().getTime()}`,
                    Type: [
                        {
                            __component: 'documents.title-deed',
                            Number: data.Scanned.DocumentNumber,
                            Year: nanToNull(data.Scanned.DocumentYear),
                            Type: data.property_type// data.Scanned.DocumentType
                        }
                    ]
                }, undefined, undefined, getState().modal.scannedData.failedToScan.url))
                if (response.isSuccessful) {
                    data.Documents = [...data.Documents, { id: response?.response?.data?.id }]
                }
            }

            const unitDocsIDs = data.Documents.map((doc: any) => doc.id)
            const unitTitleDeedDocs = getState().documents.documents.filter((doc) => unitDocsIDs.includes(doc.id) === true && doc?.attributes?.Type?.[0]?.__component === 'documents.title-deed')
            const titleDeedDoc = unitTitleDeedDocs.length > 0 ? unitTitleDeedDocs[unitTitleDeedDocs.length - 1] : undefined
            console.log(titleDeedDoc, data.property_type)
            if (titleDeedDoc != null && data.property_type !== titleDeedDoc?.attributes?.Type?.[0]?.Type) {
                await dispatch(updateDocument({
                    id: titleDeedDoc.id,
                    data: {
                        Type: [{
                            Type: data.property_type
                        }]
                    }
                }))
            }

            data = _.omit(data, ['Owners', 'Scanned'])
        }

        if (isMarketing && unit?.attributes?.Marketing == null) {
            data.CreateDate = new Date()
        }

        const bodyObject = {
            method: 'PUT',
            collection: 'units',
            id: unitID,
            body: data,
            query: getQueryUnits()
            // query: qs.stringify({
            //     populate: unitsPopulate
            // }, {
            //     encodeValuesOnly: true
            // })
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })

        const responseJSON = await response.json() as unknown as any
        responseJSON.isSuccessful = responseJSON?.response?.error == null
        if (!isMarketing && responseJSON.isSuccessful === true) {
            responseJSON.textNavigate = '/landlord/my_units'
            responseJSON.textNotification = 'Unit updated successfully'
        }
        if (isMarketing && responseJSON.isSuccessful === true) {
            if (unit?.attributes?.Marketing == null && !data.Statuses.includes('Drafted')) {
                void sendUnitNotification(responseJSON.response.data as Unit, 'user-unit-new')
            }
        }
        const { isSuccessful } = dispatch(singleUnitFetchingSuccess(responseJSON))
        if (!isSuccessful) {
            void dispatch(fetchUser(true))
            void dispatch(fetchUnits(true))
        }
        if (!isMarketing) {
            void dispatch(processPropertyDeleteTasks(Number(data.Property)))
        }
        dispatch(dataFetchingFinish())
        return responseJSON
    } catch (e) {
        console.error('Error while updating unit: ', e)
        dispatch(dataFetchingFinish())
        return {
            isSuccessful: false
        }
    }
}

export const archiveUnit = (unitID: number, navigate?: NavigateFunction) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const unit = getState().units.units.find(unitItem => unitItem.id === unitID)
    const Statuses = (unit?.attributes?.Statuses ?? []).filter(status => status !== 'Archived')
    const unitIsAvailable = unit?.attributes?.Statuses?.includes('Archived') !== true
    if (unitIsAvailable) {
        Statuses.push('Archived')
    }
    
    const promptResponse = await Swal.fire({
        ...swalOptions('info'),
        title: !unitIsAvailable ? 'This unit will be unarchived' : 'This unit will be archived',
        text: !unitIsAvailable ? 'It will be available in the catalog. Are you sure you want to continue?' : 'It will be removed from the catalog. Are you sure you want to continue?',
        showCancelButton: true,
        cancelButtonText: 'Go back',
        showConfirmButton: true,
        confirmButtonText: !unitIsAvailable ? 'Unarchive unit' : 'Archive unit'
    })
    if (promptResponse.isDismissed) {
        if (navigate != null) {
            navigate(1)
        }

        return {
            isSuccessful: false,
            textNavigate: -1
        }
    }

    dispatch(dataFetchingStart())
    const currentUnit = store.getState().units.units.filter((u) => u.id === unitID)

    const bodyObject = {
        method: 'PUT',
        collection: 'units',
        id: unitID,
        body: {
            Statuses,
            CreateDate: !unitIsAvailable ? new Date() : null
        },
        query: getQueryUnits()
    }

    const response = await fetch(`${urlAPI}`, {
        method: 'POST',
        body: JSON.stringify(bodyObject),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`
        }
    })

    const responseJSON = await response.json() as unknown as any
    if (!unitIsAvailable && !currentUnit[0].attributes.Statuses.includes('Drafted') && !currentUnit[0].attributes.Statuses.includes('Sold')) {
        void sendUnitNotification(responseJSON.response.data as Unit, 'user-unit-new')
    }
    const { isSuccessful } = dispatch(singleUnitFetchingSuccess(responseJSON))
    if (!isSuccessful) {
        await dispatch(fetchUnits(true))
        await dispatch(fetchUser(true))
    }
    dispatch(dataFetchingFinish())
    return responseJSON
}

export const fetchSingleUnit = (unitID: number): any => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    return async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
        try {
            const bodyObject = {
                method: 'GET',
                collection: 'units',
                id: unitID,
                query: getQueryUnits()
            }

            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${String(jwt)}`
                },
                body: JSON.stringify(bodyObject)
            })

            const responseJSON = await response.json()

            if (responseJSON.success === true) {
                if (responseJSON.response.error != null) { /* empty */ } else {
                    return responseJSON.response?.data?.attributes
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export const sendUnitNotification = async (unit: Unit, rule: string, delay: number = NOTIFY_DELAY, text?: string): Promise<{ isSuccessful: boolean }> => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const sentBodyForNotification = {
            method: 'POST',
            collection: 'user-notifies',
            body: {
                mode: 'publish',
                executeAt: notifyDelay(delay),
                RuleID: unit.id,
                Rule: rule,
                User: (unit.attributes as any)?.User?.data?.id,
                Text: text,
                Type: 'Mobile',
                publishedAt: null
            },
            query: qs.stringify({
                populate: ['User']
            }, {
                encodeValuesOnly: true
            })
        }

        const notificationResponse = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sentBodyForNotification),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${String(jwt)}`
            }
        })
        const notificationResponseJSON = await notificationResponse.json()
        notificationResponseJSON.isSuccessful = notificationResponseJSON?.response?.error == null
        return notificationResponseJSON
    } catch (e) {
        return {
            isSuccessful: false
        }
    }
}

export const setUnitSold = (unitID: number, setInProcess: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    const unit = _.cloneDeep(getState().units.units?.find((unit) => unit.id === unitID))
    if (unit == null) {
        console.error(`Unit with id ${unitID} doesn't exist in application state`)
        return {
            isSuccessful: false
        }
    }
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const isTestingUnit = unit?.attributes?.Property?.data?.attributes?.Name?.toLowerCase()?.includes('testing') === true || unit?.attributes?.Marketing?.Headline?.toLowerCase()?.includes('testing') === true
    const bodyObject = {
        method: 'PUT',
        collection: 'units',
        id: unitID,
        body: {
            Statuses: [...(unit.attributes.Statuses).filter(status => !['Sold', 'In-process'].includes(status)), !setInProcess ? 'Sold' : 'In-process'],
            HideDate: isTestingUnit
                ? new Date(new Date().getTime() - 1000 * 60 * 60 * 24)
                : !setInProcess ? new Date(new Date().getTime() + 1000 * 60 * 60 * 24) : null
        },
        query: getQueryUnits()
    }

    const response = await fetch(`${urlAPI}`, {
        method: 'POST',
        body: JSON.stringify(bodyObject),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`
        }
    })

    const responseJSON = await response.json() as unknown as any
    responseJSON.isSuccessful = responseJSON.success


    const userBroadcastMessages = getState().chat.userBroadcastMessages
    if(userBroadcastMessages.length > 0){
        const currentBroadcast = userBroadcastMessages.find((_broadcast) => _broadcast?.Unit?.attributes?.id === Number(unitID) )
        if(currentBroadcast != null){
            const responseDeleteMessage = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify({
                    method: 'DELETE',
                    collection: 'messages',
                    id : currentBroadcast.id
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                }
            })
            await responseDeleteMessage.json()
        }
        await dispatch(getChatList())
    }


    const { isSuccessful } = dispatch(singleUnitFetchingSuccess(responseJSON))
    if (!isSuccessful) {
        await dispatch(fetchUnits(true))
        await dispatch(fetchUser(true))
    }
    if (setInProcess) {
        if (!unit.attributes.Statuses.includes('Drafted') && !unit.attributes.Statuses.includes('Archived')) {
            void sendUnitNotification(responseJSON.response.data as Unit, 'user-unit-new')
        }
        return responseJSON
    }
    await sendUnitNotification(unit, 'user-unit-sold')

    return responseJSON
}

export const setUnitHidden = (unitID: number, setShown: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    const unit = _.cloneDeep(getState().units.units?.find((unit) => unit.id === unitID))
    if (unit == null) {
        console.error(`Unit with id ${unitID} doesn't exist in application state`)
        return {
            isSuccessful: false
        }
    }
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const statuses = unit.attributes.Statuses
    const bodyObject = {
        method: 'PUT',
        collection: 'units',
        id: unitID,
        CreateDate: setShown ? new Date() : null,
        body: {
            Statuses: !setShown ? [...statuses, 'Drafted'] : statuses.filter(status => status !== 'Drafted')
        },
        query: getQueryUnits()
    }

    const response = await fetch(`${urlAPI}`, {
        method: 'POST',
        body: JSON.stringify(bodyObject),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`
        }
    })

    const responseJSON = await response.json() as unknown as any
    responseJSON.isSuccessful = responseJSON.success
    const { isSuccessful } = dispatch(singleUnitFetchingSuccess(responseJSON))
    if (!isSuccessful) {
        await dispatch(fetchUnits(true))
        await dispatch(fetchUser(true))
    }

    if (setShown && !unit.attributes.Statuses.includes('Archived') && !unit.attributes.Statuses.includes('Sold')) {
        void sendUnitNotification(responseJSON.response.data as Unit, 'user-unit-new')
    }

    return responseJSON
}

export const singleUnitFetchingSuccess = (responseJSON: Record<string, any>) => (dispatch: AppDispatch, getState: () => RootState): { isSuccessful: boolean } => {
    try {
        const unitObject = responseJSON.response?.data
        if (unitObject?.id == null) {
            return ({
                isSuccessful: false
            })
        }
        const units = _.cloneDeep(getState().units.units)

        const updatedUnits = [
            ...units.filter(unit => unit.id !== unitObject.id),
            unitObject
        ]
        dispatch(unitsSlice.actions.unitsFetchingSuccess(updatedUnits))

        const currentUser = _.cloneDeep(getState().user.currentUser)

        if (unitObject?.attributes?.User?.data?.id === currentUser.id) {
            const unitObjectNoDataAttributes = removeDataAttributes(_.cloneDeep(unitObject))
            currentUser.Units = [
                ...(currentUser as any)?.Units?.filter((unit: Unit) => unit.id !== unitObjectNoDataAttributes.id),
                unitObjectNoDataAttributes
            ].sort((unitA, unitB) => unitA.id > unitB.id ? 1 : unitA.id < unitB.id ? -1 : 0)
            dispatch(userFetchingSuccess(currentUser as User))
        }
        return {
            isSuccessful: true
        }
    } catch (e) {
        console.error(e)
        return {
            isSuccessful: false
        }
    }
}
