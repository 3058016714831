
import React, { useState, type Dispatch, type SetStateAction } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { type RootState } from '../../../../../store/store'
import Modal from 'antd/es/modal/Modal'
import { addUserToFavorites, blockUser } from '../../../../../store/actionsCreators/userActions'
import { type ISettings, type User } from '../../../../../store/slices/userSlice'
import _ from 'lodash'
import { openChatByUserID } from '../../../../../store/actionsCreators/chatActions'
import ActionButtons from '../../ActionButtons'
import Icon from '../../../../../app/icon/Icon'
import CloseIconBasic from '../../../../shared/elements/CloseIconBasic'
import { ava_blank } from '../../../../../urls'
interface IProps {
    previewOpen?: boolean
    setPreviewOpen: Dispatch<SetStateAction<boolean>>
    currentUser: User
    previewUser: User
    id: string | undefined
}
export default function OtherProfileHead({
    previewOpen,
    previewUser,
    id,
    setPreviewOpen,
    currentUser
}: IProps): JSX.Element {
    const userCONTACTS = (previewUser)?.Type?.[0]
    const userContactsINFO = (previewUser)?.ContactInfo
    const userSettings = previewUser?.Settings as ISettings[]

    const [imageUrl, setImageUrl] = useState<string>(
        userCONTACTS?.Photo ?? ava_blank
    )
    const licenseApproved = useAppSelector(
        (state: RootState) => state.agentStates.statusesApproved.licenseApproved
    )

    let fullName =
        (userContactsINFO?.FirstName ? userContactsINFO.FirstName : userCONTACTS?.Name) +
        ' ' +
        (userContactsINFO?.FamilyName ? userContactsINFO.FamilyName : '')
    fullName === 'undefined ' ? fullName = 'No name' : null
    const maxLength = 28

    return (
        <>
            <div className="d-flex flex-sm-nowrap ">
                <div className="me-7 mb-4">
                    <div
                        onClick={() => {
                            setPreviewOpen(true)
                        }}
                        className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
                    >
                        <img
                            className="object-fit-cover border rounded"
                            src={imageUrl}
                            alt="image"
                        />
                        {/* <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div> */}
                    </div>
                    <Modal
                        open={previewOpen}
                        footer={null}
                        onCancel={() => {
                            setPreviewOpen(false)
                        }}
                        closeIcon={
                            <CloseIconBasic onCancel={() => {
                                setPreviewOpen(false)
                            }} cls={''} style={{ top: '-5px', right: '-5px' }} />
                        }
                        centered
                        styles={{
                            body: {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '50vh'
                            }
                        }}
                    >
                        <img
                            alt="example"
                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                            src={imageUrl}
                            className='object-fit-cover w-100 h-100'
                        />
                    </Modal>
                </div>
                <div className="flex-grow-1">
                    <div className="d-flex justify-content-between align-items-start  mb-1">
                        <div className="d-flex flex-column">
                            <div className="d-flex align-items-center mb-1">
                                {/* text-dots-app max-w-100px */}
                                <span className="flex-wrap text-gray-900   fs-2 fw-bold me-1 lh-1">
                                    {fullName.length > maxLength
                                        ? fullName.slice(0, maxLength) + '...'
                                        : fullName}
                                    {/* {fullName} */}

                                </span>
                                <span className={'d-flex align-items-center'}>
                                    {licenseApproved
                                        ? <Icon name={'verified'} className={'fs-1 text-warning'} />
                                        : <Icon name={'verified'} className={'fs-1 text-primary'} />}

                                </span>
                            </div>

                            {/* <div className="d-flex justify-content-between align-items-end fw-semi-bold fs-6 pe-2">
                                <span

                                    className="d-flex align-items-center text-gray-400  me-5 mb-1"
                                >
                                    <Icon name={'leaderboard'} className={'fs-1 fs-4 me-1 text-muted'}/>
              Rating {userSettings?.[0]?.Performance ?? 0}
                                </span>

                            </div> */}

                            <div className="d-flex justify-content-between align-items-center fw-semi-bold fs-5">
                                <span
                                    className="d-flex align-items-center text-gray-600 fs-5 me-5 mb-1 gap-2">
                                    <Icon name={'location_on'} className={'fs-2x'} />
                                    <span>
                                        {userContactsINFO?.Country}
                                    </span>
                                </span>
                            </div>
                            {/* <div className="d-flex justify-content-between align-items-center fw-semi-bold fs-5"> */}
                            {/*    <span */}
                            {/*        className="d-flex align-items-center text-gray-600 fs-5 me-5 mb-1 gap-2"> */}
                            {/*        <Icon name={'work'} className={'fs-2x'} /> */}
                            {/*        <span> */}
                            {/*            Agency Name */}
                            {/*        </span> */}
                            {/*    </span> */}
                            {/* </div> */}
                        </div>

                    </div>

                </div>

            </div>

        </>

    )
}
